import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/global.css'
import './src/styles/variables.css'

import Layout from './src/components/layout'

export const wrapPageElement = ({ element, props }) => {
  return <Layout>{element}</Layout>
};
