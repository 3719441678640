import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagramSquare, faFacebookSquare, faLinkedin, faTwitter, faTiktok } from "@fortawesome/free-brands-svg-icons";

import * as styles from './social-media.module.css'

export default function SocialIcons() {

  return(
    <div className={styles.social_icons}>
      <a href="https://www.instagram.com/tedxki/?hl=en" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagramSquare} className="fa-3x" /></a>
      <a href="https://www.linkedin.com/company/tedxki/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} className="fas fa-3x" /></a>
      <a href="https://www.tiktok.com/@tedxki" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTiktok} className="fa-3x" /></a>
    </div>
  )
};
